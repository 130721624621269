import React from 'react';
import {createRoot} from 'react-dom/client';
import './fonts/Share_Tech/ShareTech-Regular.ttf';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux';
import store from './store';
import Root from "./root";
import {BrowserRouter} from "react-router-dom";
import './index.css';
import 'highlight.js/styles/github.css';
import './auth'


// react-redux-firebase config
/*
const rrfConfig = {
    //userProfile: 'users',
    useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
}

const rrfProps = {
    firebase,
    config: rrfConfig,
    dispatch: store.dispatch,
    createFirestoreInstance // <- needed if using firestore
}
*/


const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
/*root.render(<I18nextProvider i18n={i18next}>
    <Provider store={store}>
        <App/>
    </Provider>
</I18nextProvider>);*/
root.render(
    <Provider store={store}>
        <BrowserRouter>
            <Root/>
        </BrowserRouter>
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
