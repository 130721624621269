import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    darkMode: localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches),
};

const slice = createSlice({
    name: "app",
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload || false;
        },
    },
});


export default slice.reducer;

// ACTIONS

const {setLoading} = slice.actions;

export const setAppLoading = (isLoading = true) => setLoading(isLoading)

// SELECTORS

export const loadingSelector = (state) => state.app.loading;
export const darkModeSelector = (state) => state.app.darkMode;
