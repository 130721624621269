import "./firebase"
import {Navigate, Route, Routes} from "react-router-dom";
import {useSelector} from "react-redux";
import {darkModeSelector, loadingSelector} from "./store/app";
import Spinner from "./components/spinner";
import {RequireAuth, RequireNonAuth} from "./guards/auth";
import React, {Suspense, useLayoutEffect} from "react";
import SignIn from "./pages/signIn";
import ChatTest from "./pages/chat/test";
import Landing from "./landing";
import ShopDemo from "./pages/chat/shop";

const LazyApp = React.lazy(() => import("./app"));

export default function Root() {
    const isLoading = useSelector(loadingSelector)
    const darkMode = useSelector(darkModeSelector)

    useLayoutEffect(() => {
        /*if (darkMode) document.documentElement.classList.add("dark")
        else document.documentElement.classList.remove("dark")*/
    }, [darkMode])

    return (
        <>
            <Routes>
                <Route path="/" element={<Landing/>}/>
                <Route path="/u" element={<Navigate to={"/u/dashboard"}/>}/>

                <Route path="/chat/:botId" element={<ChatTest/>}/>
                <Route path="/shop-demo" element={<ShopDemo/>}/>

                {/*<Route path="/sign-out" element={<SignOut/>}/>*/}
                {/*<Route path="/chat/*" element={<LazyChat/>}/>*/}
                <Route path="/sign-in" element={
                    <RequireNonAuth>
                        <SignIn/>
                    </RequireNonAuth>
                }/>
                <Route path="/u/*" element={
                    <RequireAuth>
                        <Suspense fallback={<Spinner big/>}>
                            <LazyApp/>
                        </Suspense>
                    </RequireAuth>
                }/>
                {/*<Route path="*" element={<NotFound/>}/>*/}
            </Routes>
            {isLoading && (
                <div className="fixed inset-0 bg-gray-100 z-10">
                    <Spinner big/>
                </div>
            )}
        </>
    )
}
