//import createEngine from 'redux-storage-engine-localstorage';
//import user from "./reducers/user";
//import notifications from "./reducers/notifications";
//import {firebaseReducer} from "react-redux-firebase";
//import {firestoreReducer} from "redux-firestore";
/*import loader from './store/loader';
import table from './store/table';
import invitation from './store/invitation';
import agreements from './store/agreements';
import platform from './store/platform';
import profile from './store/profile';
import organization from './store/organization';
import logs from './store/logs';
import partners from './store/partners';
import financing from './store/financing';
import invoices from './store/invoices';
import payables from './store/payables';
import receivables from './store/receivables';
import actionsView from "./store/actionsView";*/
import app from "./store/app"
import bot from "./store/bot"
import {configureStore} from "@reduxjs/toolkit";

/*const reducer = storage.reducer(combineReducers({
    //firebase: firebaseReducer,
    //firestore: firestoreReducer,
    user,
    profile,
    organization,
    loader,
    invitation,
    agreements,
    platform,
    logs,
    partners,
    financing,
    notifications,
    actionsView
}));*/


//const engine = createEngine('my-save-key');

//const middleware = storage.createMiddleware(engine);


//const createStoreWithMiddleware = applyMiddleware(middleware)(createStore);
//const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk)));
const store = configureStore({
    reducer: {
        app,
        bot,
    }
});

/*const load = storage.createLoader(engine);
load(store)
    .then((newState) => console.log('Loaded state:', newState))
    .catch(() => console.log('Failed to load previous state'));*/

export default store;
