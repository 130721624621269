import React from "react";
import {classNames} from "../utils";

export function Logo({light, big}) {
    return (
        <div className={classNames(
            "w-auto font-medium",
            light ? "text-primary-400" : "text-primary-600",
            big ? "text-4xl" : "text-xl"
        )}><span className="opacity-75">AI</span><span className="opacity-100">TOOLKIT</span></div>
    )
}
