import axios from "axios";

export const API_URL = process.env.REACT_APP_API_URI;
export const STREAMING_API_URI = process.env.REACT_APP_STREAMING_API_URI;
export const IS_ADMIN = process.env.REACT_APP_ADMIN === "true";

export const API = axios.create({
    baseURL: API_URL,
})

