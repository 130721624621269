import {AuthLayout} from '../components/AuthLayout'
import {Button} from '../components/Button'
import {TextField} from '../components/Fields'
import {Logo} from '../components/Logo'
import {Link, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {getAuth, isSignInWithEmailLink, sendSignInLinkToEmail, signInWithEmailLink} from "firebase/auth";
import {useDispatch, useSelector} from "react-redux";
import {loadingSelector, setAppLoading} from "../store/app";

export default function SignIn() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const auth = getAuth()

    const isLoading = useSelector(loadingSelector)

    const [email, setEmail] = useState("")

    const [isNoteVisible, setIsNoteVisible] = useState(false)

    const [mustConfirmEmail, setMustConfirmEmail] = useState(false)

    useEffect(() => {
        // Confirm the link is a sign-in with email link.
        if (isSignInWithEmailLink(auth, window.location.href)) {
            let email = window.localStorage?.getItem ? window.localStorage.getItem('emailForSignIn') : null
            if (!email) setMustConfirmEmail(true)
            else signIn(email)
        }
    }, [])

    function signIn(email) {
        dispatch(setAppLoading(true))
        signInWithEmailLink(auth, email, window.location.href)
            .then(() => {
                if (window.localStorage?.removeItem) window.localStorage.removeItem('emailForSignIn');
                navigate("/u/dashboard")
            })
            .catch((error) => {
                // Some error occurred, you can inspect the code: error.code
                // Common errors could be invalid email and invalid or expired OTPs.
                console.error(error)
            })
            .finally(() => {
                dispatch(setAppLoading(false))
            })
    }

    function onSubmit(e) {
        e.preventDefault();

        if (isLoading) return

        if (mustConfirmEmail) {
            signIn(email)
            return
        }

        dispatch(setAppLoading(true))
        sendSignInLinkToEmail(auth, email, {
            url: window.location.href,
            handleCodeInApp: true,
        })
            .then((res) => {
                if (window.localStorage?.setItem) window.localStorage.setItem('emailForSignIn', email);
                setIsNoteVisible(true)
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                dispatch(setAppLoading(false))
            })
    }

    return (
        <>
            <AuthLayout>
                <div className="flex flex-col">
                    <Link to="/" aria-label="Home">
                        <Logo big/>
                    </Link>
                    {isNoteVisible ? (
                        <div className="mt-20">
                            <h2 className="text-lg font-semibold text-gray-900">
                                Check your email
                            </h2>
                            <p className="mt-2 text-sm text-gray-700">
                                We've sent you an email with a confirmation link. <br/><span className="font-medium">Click the confirmation link to sign in.</span>
                            </p>
                        </div>
                    ) : mustConfirmEmail ? (
                        <div className="mt-20">
                            <h2 className="text-lg font-semibold text-gray-900">
                                Verify your email address
                            </h2>
                            <p className="mt-2 text-sm text-gray-700">
                                We've noticed you've came here from a different browser. <br/><span
                                className="font-medium">Please verify your email to continue.</span>
                            </p>
                        </div>
                    ) : (
                        <div className="mt-20">
                            <h2 className="text-lg font-semibold text-gray-900">
                                Let's start
                            </h2>
                            <p className="mt-2 text-sm text-gray-700">
                                Enter your email address below. <span className="font-medium">You'll receive confirmation code.</span>
                            </p>
                        </div>
                    )}
                </div>
                {!isNoteVisible && (
                    <form
                        onSubmit={onSubmit}
                        className="mt-10 grid grid-cols-1 gap-y-8">
                        <TextField
                            label="Email address"
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            required
                        />
                        <div>
                            <Button
                                type="submit"
                                variant="solid"
                                color="primary"
                                className="w-full"
                            >
              <span>
                Sign in <span aria-hidden="true">&rarr;</span>
              </span>
                            </Button>
                        </div>
                    </form>
                )}
            </AuthLayout>
        </>
    )
}
