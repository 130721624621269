import {createSlice} from "@reduxjs/toolkit";
import {getAuth} from "firebase/auth";
import {collection, doc, getDoc, getFirestore, onSnapshot, orderBy, query, where} from "firebase/firestore";
import {getFirestampDateString} from "../utils";
import {IS_ADMIN} from "../config";

const initialState = {
    ordered: [],
};

let listener = null

const slice = createSlice({
    name: "bot",
    initialState,
    reducers: {
        setBotState: (state, action) => ({
            ...state,
            ordered: action.payload.ordered,
            ...action.payload.map,
        }),
        updateBot: (state, action) => ({
            ...state,
            ...action.payload.map,
        }),
    },
});


export default slice.reducer;

// ACTIONS

const {setBotState, updateBot} = slice.actions;

export const subscribeToBotList = (archived = false) => (dispatch) => {
    const auth = getAuth(), db = getFirestore(),
        w = IS_ADMIN ? [] : [where("uid", "==", auth.currentUser.uid)],
        q = query(collection(db, "bots"), ...w, archived ? where("status", "==", "archived") : orderBy("createdAt", "desc"));
    if (listener) listener()
    listener = onSnapshot(q, (querySnapshot) => {
        const ordered = [], map = {};
        querySnapshot.forEach((doc) => {
            const docData = doc.data(),
                data = {
                    ...docData,
                    id: doc.id,
                    createdAt: getFirestampDateString(docData.createdAt),
                    updatedAt: getFirestampDateString(docData.createdAt),
                    lastSuccessfulVdbUpsert: getFirestampDateString(docData.createdAt)
                }
            ordered.push(data);
            map[doc.id] = data
        });
        dispatch(setBotState({ordered, map}))
    })
}

export const getBot = (id) => (dispatch) => {
    const db = getFirestore()
    getDoc(doc(db, "bots", id)).then(doc => {
        const docData = doc.data(),
            data = {
                ...docData,
                id: doc.id,
                createdAt: getFirestampDateString(docData.createdAt),
                updatedAt: getFirestampDateString(docData.createdAt),
                lastSuccessfulVdbUpsert: getFirestampDateString(docData.createdAt)
            }
        dispatch(setBotState({map: {[doc.id]: data}}))
    })
}

// SELECTORS

export const botListSelector = (state) => state.bot.ordered;
export const botSelector = (id) => (state) => state.bot[id] || null;
