import {getAuth} from "firebase/auth";
import {API} from "./config";

const auth = getAuth()

let tokenExpiresAt = 0

auth.onAuthStateChanged(user => {
    user && user.getIdTokenResult().then(res => {
        API.defaults.headers.common['Authorization'] = "Bearer " + res.token;
        tokenExpiresAt = res.claims.exp * 1000
    })
})

API.interceptors.request.use(async function (config) {
    if (tokenExpiresAt < Date.now()) {
        console.log("token expired")
        auth.currentUser?.getIdTokenResult && await auth.currentUser?.getIdTokenResult(true).then(res => {
            API.defaults.headers.common['Authorization'] = "Bearer " + res.token;
            tokenExpiresAt = res.claims.exp * 1000
            config.headers['Authorization'] = "Bearer " + res.token;
            console.log("token refreshed")
        })
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});
API.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    //console.log("sharedApi.interceptors.response", response.status, response.statusText)

    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    //console.log("sharedApi.interceptors.response.error", error, error.response?.status)

    if (error.response?.status === 401) {
        window.location.reload()
    }

    return Promise.reject(error);
});
