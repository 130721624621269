import React, {Fragment, useEffect, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {ArrowLongLeftIcon, Bars3Icon, XMarkIcon,} from '@heroicons/react/24/outline'
import {NavLink, useLocation} from "react-router-dom";

import {classNames} from "../../utils";
import {getAuth, onAuthStateChanged} from "firebase/auth";
import Spinner from "../../components/spinner";
import {Logo} from "../../components/Logo";
import {Chat, Product, useBot} from "../../components/bot-widget";

const navigation = [
    {name: 'Back to dashboard', href: '/u/dashboard', icon: ArrowLongLeftIcon, current: true},
]

const products = [
    {
        "id": 1,
        "h": "KOZAREC ZA RDEČE VINO – ARMONIA – SET 2 KOS",
        "desc": "Armonia serija s posebnim dnom za hitrejši razvoj arome vina.",
        "price": 1000,
        "cat": "kozarci",
        "thumb": "https://www.godigi-ai.com/wp-content/uploads/steklarna/1177431-armonia-vino.jpg"
    },
    {
        "id": 3,
        "h": "KROŽNIK 18 CM S POKROVOM – CROWN JEWEL",
        "desc": "Eleganten krožnik s pokrovom iz kakovostnega stekla Steklarne Rogaška.",
        "price": 69.90,
        "cat": "steklen-kroznik-s-pokrovom",
        "thumb": "https://www.godigi-ai.com/wp-content/uploads/steklarna/crown-jewel-platter-18-cm-with-cover.jpg"
    },
    {
        "id": 4,
        "h": "SKLEDA 12 CM S POKROVOM – EQUINOX",
        "desc": "Elegantna in praktična skleda s pokrovom iz visokokakovostnega stekla.",
        "price": 51.90,
        "cat": "steklena-skleda",
        "thumb": "https://www.godigi-ai.com/wp-content/uploads/steklarna/equinox_small-box.jpg"
    },
    {
        "id": 5,
        "h": "KOZAREC ZA KONJAK – VENERE",
        "desc": "Kolekcija Venere s privlačno obliko, primerna za razvoj značilnosti vina.",
        "price": 65.80,
        "cat": "kozarci-za-konjak",
        "thumb": "https://www.godigi-ai.com/wp-content/uploads/steklarna/VENERA-cognac-350-ml-131561.jpg"
    },
    {
        "id": 6,
        "h": "KROŽNIK S POKROVOM 33 CM NA PODSTAVKU – CROWN JEWEL",
        "desc": "Prefinjen krožnik s pokrovom za serviranje velikih obrokov, z elegantnim dizajnom.",
        "price": 244.90,
        "cat": "steklen-kroznik-s-pokrovom",
        "thumb": "https://www.godigi-ai.com/wp-content/uploads/steklarna/crown-jewel-ftd.jpg"
    },
    {
        "id": 7,
        "h": "VAZA 31 CM – ROCKSTAR",
        "desc": "Vaza z ritmičnim kontrastom med lomljenimi in čistimi površinami.",
        "price": 198.90,
        "cat": "steklena-vaza",
        "thumb": "https://www.godigi-ai.com/wp-content/uploads/steklarna/ROCKSTAR-vaza.jpg"
    },
    {
        "id": 8,
        "h": "VAZA 32 CM – AMPHORA GOLD",
        "desc": "Klasična vaza z bleščečim brusom in 18-karatno pozlato.",
        "price": 579.90,
        "cat": "steklena-vaza",
        "thumb": "https://www.godigi-ai.com/wp-content/uploads/steklarna/AMPHORA_vase-32-cm.jpg"
    },
    {
        "id": 9,
        "h": "VAZA 25 CM – MAGIC NIGHT GOLD",
        "desc": "Elegantna vaza z zlatimi gravurami in reliefi za prefinjen dom.",
        "price": 479.90,
        "cat": "steklena-vaza",
        "thumb": "https://www.godigi-ai.com/wp-content/uploads/steklarna/MAGIC-NIGHT-GOLD-VASE.jpg"
    },
    {
        "id": 10,
        "h": "VAZA 45 CM NA PODSTAVKU",
        "desc": "Vrhunska vaza na podstavku z detajlnimi gravurami in reliefi.",
        "price": 2229.90,
        "cat": "steklena-vaza",
        "thumb": "https://www.godigi-ai.com/wp-content/uploads/steklarna/neptun-no-cut-vase.jpg"
    },
    {
        "id": 11,
        "h": "STOJALO ZA SADJE – ETAŽER – CROWN JEWEL",
        "desc": "Etažer s tremi ravni za razstavljanje sadja in dekoracije.",
        "price": 329.90,
        "cat": "stojalo-za-sadje",
        "thumb": "https://www.godigi-ai.com/wp-content/uploads/steklarna/CROWN-JEWEL-ETAGERE.jpg"
    },
    {
        "id": 12,
        "h": "HIGHBALL KOZAREC ZA SOK, PIVO, KOKTEJL",
        "desc": "Prostoren kozarec Highball za večje količine pijače.",
        "price": 57.80,
        "cat": "stojalo-za-sadje",
        "thumb": "https://www.godigi-ai.com/wp-content/uploads/steklarna/FLAME-highball.jpg"
    },
    {
        "id": 13,
        "h": "POSODA ZA LED – OLYMP",
        "desc": "Elegantna posoda za led z ročno izdelanimi gravurami in reliefi.",
        "price": 119.90,
        "cat": "posoda-za-led",
        "thumb": "https://www.godigi-ai.com/wp-content/uploads/steklarna/OLYMP-posoda-za-led.jpg"
    },
    {
        "id": 14,
        "h": "KOKTEJL SHAKER – MEŠALNIK – AVENUE",
        "desc": "Kolekcija ‘Avenue’ s sodobnim videzom in globokimi V-brusi.",
        "price": 97.90,
        "cat": "koktej-shaker",
        "thumb": "https://www.godigi-ai.com/wp-content/uploads/steklarna/AVENUE_Coctail-Shaker.jpg"
    },
    {
        "id": 15,
        "h": "DEKANTER ZA VINO PARABOLA – AUREA",
        "desc": "Dekanter za vino z elegantnim videzom in učinkovitim zračenjem.",
        "price": 148.90,
        "cat": "dekanter",
        "thumb": "https://www.godigi-ai.com/wp-content/uploads/steklarna/Aurea_parabola.jpg"
    }
]

const essentials = {
    "findProducts": (props) => <Product products={products} item={props}/>
}

export default function ShopDemo() {
    const botId = "1xrBdjIOf9RVGEcqq6LR"
    const location = useLocation()
    const [user, setUser] = useState(false)
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const bot = useBot({
        id: botId,
        enableLocalStorage: true,
        customCommands: [
            {
                command: "findProducts",
                params: ["string"],
                input: "Imaš kakšno idejo kaj naj kupim za rojstno dnevno darilo svoji tašči?",
                output: "Seveda, lahko razmislim o nekaj darilih, ki bi jih vaša tašča morda cenila. Za začetek sem našel ročno izdelane kristalne vaze:\n[`7, 8, 9, 10`]\nČe želite več možnosti, mi lahko poveste več o njenih interesih in hobijih, da bom lahko bolje prilagodil predloge.",
            }
        ]
    })

    useEffect(() => {
        sidebarOpen && setSidebarOpen(false)
    }, [location.pathname])

    useEffect(() => {
        onAuthStateChanged(getAuth(), (user) => {
            setUser(user)
        });
    }, [])

    return (
        <>
            {user === false ? (
                <Spinner className="bg-gray-900 text-gray-100" big/>
            ) : (
                <div className="flex h-full">
                    <Transition.Root show={sidebarOpen} as={Fragment}>
                        <Dialog as="div" className="relative z-40 lg:hidden" onClose={setSidebarOpen}>
                            <Transition.Child
                                as={Fragment}
                                enter="transition-opacity ease-linear duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="transition-opacity ease-linear duration-300"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="fixed inset-0 bg-gray-600 bg-opacity-75"/>
                            </Transition.Child>

                            <div className="fixed inset-0 z-40 flex">
                                <Transition.Child
                                    as={Fragment}
                                    enter="transition ease-in-out duration-300 transform"
                                    enterFrom="-translate-x-full"
                                    enterTo="translate-x-0"
                                    leave="transition ease-in-out duration-300 transform"
                                    leaveFrom="translate-x-0"
                                    leaveTo="-translate-x-full"
                                >
                                    <Dialog.Panel
                                        className="relative flex w-full max-w-xs flex-1 flex-col focus:outline-none bg-gray-800">
                                        <Transition.Child
                                            as={Fragment}
                                            enter="ease-in-out duration-300"
                                            enterFrom="opacity-0"
                                            enterTo="opacity-100"
                                            leave="ease-in-out duration-300"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                        >
                                            <div className="absolute top-0 right-0 -mr-12 pt-2">
                                                <button
                                                    type="button"
                                                    className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                                    onClick={() => setSidebarOpen(false)}
                                                >
                                                    <span className="sr-only">Close sidebar</span>
                                                    <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true"/>
                                                </button>
                                            </div>
                                        </Transition.Child>
                                        <div className="h-0 flex-1 overflow-y-auto pt-5 pb-4">
                                            <div className="flex flex-shrink-0 items-center px-4">
                                                <Logo light/>
                                            </div>
                                            <nav aria-label="Sidebar" className="mt-5">
                                                <div className="space-y-1 px-2">
                                                    {navigation.map((item) => (
                                                        <NavLink
                                                            key={item.name}
                                                            to={item.href}
                                                            className={({isActive}) => classNames(
                                                                isActive
                                                                    ? 'bg-gray-900 text-white'
                                                                    : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                                                'group flex items-center rounded-md px-2 py-2 text-base font-medium'
                                                            )}
                                                        >
                                                            <item.icon
                                                                className={classNames(
                                                                    /*item.current ? 'text-gray-500' : */'text-gray-400 group-hover:text-gray-300',
                                                                    'mr-4 h-6 w-6'
                                                                )}
                                                                aria-hidden="true"
                                                            />
                                                            {item.name}
                                                        </NavLink>
                                                    ))}
                                                </div>
                                            </nav>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                                <div className="w-14 flex-shrink-0" aria-hidden="true">
                                    {/* Force sidebar to shrink to fit close icon */}
                                </div>
                            </div>
                        </Dialog>
                    </Transition.Root>

                    {/* Static sidebar for desktop */}
                    <div className="hidden lg:flex lg:flex-shrink-0">
                        <div className="flex w-64 flex-col">
                            {/* Sidebar component, swap this element with another sidebar if you like */}
                            <div className="flex min-h-0 flex-1 flex-col bg-gray-800">
                                <div className="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
                                    <div className="flex flex-shrink-0 items-center px-4">
                                        <Logo light/>
                                    </div>
                                    <nav className="mt-5 flex-1 space-y-1 px-2" aria-label="Sidebar">
                                        {navigation.map((item) => (
                                            <NavLink
                                                key={item.name}
                                                to={item.href}
                                                className={({isActive}) => classNames(
                                                    isActive
                                                        ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                                    'group flex items-center rounded-md px-2 py-2 text-sm font-medium'
                                                )}
                                            >
                                                <item.icon
                                                    className={classNames(
                                                        /*item.current ? 'text-gray-500' : */'text-gray-400 group-hover:text-gray-300',
                                                        'mr-3 h-6 w-6'
                                                    )}
                                                    aria-hidden="true"
                                                />
                                                {item.name}
                                            </NavLink>
                                        ))}
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex min-w-0 flex-1 flex-col overflow-hidden">
                        <div className="lg:hidden">
                            <div
                                className="flex items-center justify-between border-b border-gray-200 bg-gray-50 px-4 py-1.5">
                                <div>
                                    <Logo/>
                                </div>
                                <div>
                                    <button
                                        type="button"
                                        className="-mr-3 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900"
                                        onClick={() => setSidebarOpen(true)}
                                    >
                                        <span className="sr-only">Open sidebar</span>
                                        <Bars3Icon className="h-6 w-6" aria-hidden="true"/>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="relative z-0 flex flex-1 overflow-hidden bg-gray-800">
                            <main
                                className="relative z-0 flex-1 focus:outline-none xl:order-last lg:my-8 lg:ml-6 lg:mr-6 lg:rounded-lg lg:shadow-lg bg-white overflow-hidden dark">
                                <Chat bot={bot} showHeader essentials={essentials}/>
                            </main>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
