// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getFirestore} from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDnVMfJVyuM1pSgpeKX_583l3ycnzhvycE",
    authDomain: "chat-gpt-374521.firebaseapp.com",
    projectId: "chat-gpt-374521",
    storageBucket: "chat-gpt-374521.appspot.com",
    messagingSenderId: "191990522666",
    appId: "1:191990522666:web:aee48db41a987837a5b453",

};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
