import {useEffect, useState} from "react";
import {getAuth, onAuthStateChanged} from "firebase/auth";
import Spinner from "../components/spinner";
import {Navigate} from "react-router-dom";

export function RequireAuth({children}) {
    const [user, setUser] = useState(false)

    useEffect(() => {
        return onAuthStateChanged(getAuth(), (user) => {
            setUser(user)
            /*if(user) {
                const db = getFirestore(),
                    userRef = doc(db, "users", user.uid)
            }*/
        });
    }, [])

    if (user === false) return <Spinner big/>

    if (user) {
        return children
    } else {
        return <Navigate to="/sign-in"/>
    }
}

export function RequireNonAuth({children}) {
    const auth = getAuth();
    const [user, setUser] = useState(false)

    useEffect(() => {
        //setUser(getAuth().currentUser)
        return onAuthStateChanged(auth, (user) => {
            setUser(user)
        });
    }, [])

    if (user === false) return <Spinner big/>

    if (user) {
        return <Navigate to="/u/dashboard"/>
    } else {
        return children
    }
}
